import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TablePagination from '../../../components/TablePagination';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import 'react-toastify/dist/ReactToastify.css';

import toastOptions from '../../../utils/toastOptions';
import { authenticationService } from '../../../services/auth.service';
import IFileDTO from '../../../models/IFileDTO';
import MemberDocAddEdit from '../member-docs-add-edit/MemberDocAddEdit';
import { displayDateTime, showMemberTypeFile } from '../../../utils/masks';
import environment from '../../../environment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye, faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import DialogConfirm from '../../../components/DialogConfirm';

interface Params {
    memberId: number;
}

export default function MemberDocList(props: Params) {
    const { memberId } = props;

    const [refresh, doRefresh] = useState(false);  
    const [docId, setId] = useState<number>();
    const [add, doAdd] = useState(false);
    const [isReadOnly, setReadOnly] = useState<boolean>(true);
    const [ixRemoving, setIxRemoving] = useState<number>(-1);
    const [dialogDeleteText, setDialogDeleteText] = useState<string[]>([]);
    const [isDialogDelete, setIsDialogDelete] = useState<boolean>(false);
    var docSelected = useRef(0);
    var ixDocSelected = useRef(-1);
    
    const columns = [
        {
            name: 'Nome',
            grow: 2,
            cell: (row: IFileDTO) => (
                <label>
                    {row.name}
                </label>
            )
        },
        {
            name: 'Tipo',
            cell: (row: IFileDTO) => (
                <label>
                    {showMemberTypeFile(row.type)}
                </label>
            )
        },
        {
            name: 'Data',
            cell: (row: IFileDTO) => (
                <label>
                    {displayDateTime(row.modifiedDate)}
                </label>
            )
        },
        {
            name: 'Obs.',
            cell: (row: IFileDTO) => (
                <label uk-tooltip={`title: ${row.note}`}>
                    {row.note}
                </label>
            )
        },
        {
            name: 'Ações',
            button: true,
            sortable: false,
            cell: (row: IFileDTO, index: number) => (
                <div className='uk-flex' style={{justifyContent: 'space-between'}}>
                    {
                        !isReadOnly && (
                            <a href="#" uk-tooltip="title: Editar" 
                                className='uk-margin-small-right' 
                                onClick={(ev) => { ev.preventDefault(); handleEdit(row.id) }}
                            >
                                <FontAwesomeIcon icon={faPencil} />
                            </a>
                        )
                    }
                    <a href="#" 
                        uk-tooltip="title: Visualizar Documento"
                        className='uk-margin-small-right' 
                        onClick={(ev) => { ev.preventDefault(); handleView(row) }}
                    >
                        <FontAwesomeIcon icon={faEye} />
                    </a>
                    <a href="#" uk-tooltip="title: Baixar Documento"
                        className='uk-margin-small-right' 
                        onClick={(ev) => { ev.preventDefault(); handleDowload(row) }}
                    >
                        <FontAwesomeIcon icon={faDownload} />
                    </a>
                    {
                        !isReadOnly && (
                            <a href="#" uk-tooltip="title: Remover"
                                className='uk-margin-small-right'
                                onClick={(ev) => { ev.preventDefault(); handleRemove(row, index) }}
                            >
                                {
                                    (ixRemoving == index) ? <div uk-spinner="true; ratio:0.5"></div> : <FontAwesomeIcon icon={faTrash} />
                                }
                            </a>
                        )
                    }
                </div>                    
            )
        },
    ];  
    
    useEffect(()=>{
        setReadOnly(!authenticationService.canChange());        
    }, []);
    
    const fetchDocs = async (page: number, limit: number) => {
        try {
            const response = await api.get(`files/members/${memberId}`, { 
                params: { 
                    page: page, 
                    limit: limit
                }
            });
    
            return response;
        } catch {
            return {data: [], total: 0}
        }
    };

    const handleAddDoc = () => {
        doAdd(true);        
    }

    const handleEdit = async (id: number) => {
        setId(id);        
    }
    
    const handleDowload = async (file: IFileDTO) => {
        const link = document.createElement('a');
        link.href = file.fileURL;
        link.download = file.name;
        window.URL.revokeObjectURL(link.href);
        link.click();        
    }

    const handleView = async (file: IFileDTO) => {
        const link = document.createElement('a');
        link.href = file.fileURL;
        link.download = file.name;
        window.open(link.href, '_blank');
        window.URL.revokeObjectURL(link.href);
    }

    const afterHandleSubmit = () => {
        doAdd(false); 
        setId(undefined);
        doRefresh(!refresh); 
    }

    const afterCloseModal = () => {
        doAdd(false);
        setId(undefined);
    }
    
    const handleRemove = async (file: IFileDTO, ix: number) => {
        setDialogDeleteText([`Deseja realmente excluir ${file.name}?`]);
        docSelected.current = file.id;        
        setIsDialogDelete(true);
        ixDocSelected.current = ix;
    }

    const dialogClose = () => {
        setIsDialogDelete(false);
        setIxRemoving(-1);
    }

    const dialogConfirm = async (confirmation: any) => {     
        setIsDialogDelete(false);   
        try {
            setIxRemoving(ixDocSelected.current);
            const { data } = await api.delete(`files/members/${docSelected.current}`);
            toast.success('Documento removido com sucesso!', toastOptions);
            doRefresh(!refresh); 
        } catch (e: any) {
            e.messages?.forEach((m: string) => {
                toast.error(m, toastOptions);
            });
        } finally {
            setIxRemoving(-1);
        }
    }

    return (
        <div id="page-member-docs">
            <MemberDocAddEdit 
                add={add} 
                memberId={memberId} 
                docId={docId}
                afterHandleSubmit={afterHandleSubmit}
                afterCloseModal={afterCloseModal}
            /> 
            <div className="uk-text-large">
                <div className="uk-flex uk-flex-middle uk-flex-between">
                    {
                        (!isReadOnly) &&
                            <div className="uk-flex uk-margin-small-left">
                                <button className="uk-button uk-button-primary" onClick={()=> handleAddDoc()}>
                                    <div className="uk-inline-clip uk-transition-toggle">
                                        <div className="uk-transition-scale-up uk-transition-opaque">
                                            {`Adicionar `} <FontAwesomeIcon icon={faPlus} size='xl' />
                                        </div>
                                    </div>                        
                                </button>
                            </div>   
                    }
                </div>    
            </div>            
            <TablePagination
                columns={columns}
                fetchData={fetchDocs}
                refresh={refresh}
            />         
            <DialogConfirm 
                open={isDialogDelete} 
                text={dialogDeleteText} 
                afterClose={dialogClose}
                handleConfirmation={(confirmationCode) => dialogConfirm(confirmationCode)}
            />
        </div>
    )
}